import React, {useEffect, useState} from 'react';
import axios from "axios";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {yellow} from "@mui/material/colors";
import "./css/changepass.css"

const Me = (props) => {

    const [coinsPrice, setCoinsPrice] = useState([]);
    const [userData, setUserData] = useState([]);
    const [balance, setBalance] = useState("");
    const [AVG, setAVG] = useState("");

    const [name , setName] = useState(userData.name)
    const [surname , setSurname] = useState(userData.surname)
    const [email , setEmail] = useState(userData.email)
    const [country , setCountry] = useState(userData.country)
    const [city , setCity] = useState(userData.city)
    const [username , setUsername] = useState(userData.displayed_name)
    const [phone , setPhone] = useState(userData.phone_number)
    const token = localStorage.getItem('token');

    const requireUserData = () => axios.get(
        `https://api.fenixodex.net/api/api/users/me`,
        {
            headers: {"Authorization" : `Bearer ${token}`}
        })
        .then(res => {
            setUserData(res.data);
            setName(res.data.name)
            setSurname(res.data.surname)
            setEmail(res.data.email)
            setCountry(res.data.country)
            setCity(res.data.city)
            setUsername(res.data.displayed_name)
            setPhone(res.data.phone_number)
        })
    const requireBalance = () => axios.get(
        `https://api.fenixodex.net/api/api/users/balance`,
        {
            headers: {"Authorization" : `Bearer ${token}`}
        })
        .then(res => {
            setBalance(res.data);
        })
    axios.get(`https://api.binance.com/api/v3/ticker/24hr?symbols=["BTCUSDT","ETHUSDT","BNBUSDT","XRPUSDT","ADAUSDT","SOLUSDT","APEUSDT","DOTUSDT","LUNAUSDT"]`)
        .then(res => {
            setCoinsPrice(res.data);
            setAVG(coinsPrice[0].lowPrice * balance.btc + coinsPrice[1].lowPrice * balance.eth + coinsPrice[2].lowPrice * balance.bnb + coinsPrice[3].lowPrice * balance.ada + coinsPrice[4].lowPrice * balance.xrp + coinsPrice[5].lowPrice * balance.sol + coinsPrice[6].lowPrice * balance.dot + coinsPrice[7].lowPrice * balance.luna + coinsPrice[8].lowPrice * balance.ape + balance.usdt + balance.usdc  + balance.ust  + balance.busd      )

        })

    useEffect(() => {
        requireUserData();
        requireBalance();
    }, []);


    // состояния формы

    const [login , setLogin] = useState()

    const [emailDirty , setEmailDirty] = useState()
    const [emailError , setEmailError] = useState()
    const [password , setPassword] = useState("")
    const [passwordRepeat , setPasswordRepeat] = useState("")
    const [formValid, setFormValid] = useState(false);

    // обработчики формы

    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('incorrect e-mail')
            if(!e.target.value){
                setEmailError('e-mail cannot be empty!')
            }
        }
        else{
            setEmailError("")
        }
    }

    useEffect(() => {
        if (emailError ) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    },[emailError])

    const blurHandler = (e) =>  {
        switch (e.target.name){
            case 'email':
                setEmailDirty(true)
                break
        }

    };
    const handleChangeEmail = event => {
        setEmail(event.target.value);
    };
    const handleChangeName = event => {
        setName(event.target.value);
    };
    const handleChangePassword = event => {
        setPassword(event.target.value);
    };
    const handleChangePasswordRepeat = event => {
        setPasswordRepeat(event.target.value);
    };
    const handleChangeSurname = event => {
        setSurname(event.target.value);
    };
    const handleChangeLogin = event => {
        setLogin(event.target.value);
    };
    const handleChangeUsername = event => {
        setUsername(event.target.value);
    };
    const handleChangeCity = event => {
        setCity(event.target.value);
    };
    const handleChangeCountry = event => {
        setCountry(event.target.value);
    };
    const handleChangePhone = event => {
        setPhone(event.target.value);
    };
    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post(`https://api.fenixodex.net/api/api/users/edit`, {"field": "email", "value": email }, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {console.log("success")
            })
            .catch(function (error) {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )
            });
        axios.post(`https://api.fenixodex.net/api/api/users/edit`, {"field": "displayed_name", "value": username }, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {console.log("success")
            })
            .catch(function (error) {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )
            });
        axios.post(`https://api.fenixodex.net/api/api/users/edit`, {"field": "name", "value": name }, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {console.log("success")
            })
            .catch(function (error) {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )
            });
        axios.post(`https://api.fenixodex.net/api/api/users/edit`, {"field": "surname", "value": surname }, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {console.log("success")
            })
            .catch(function (error) {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )
            });
        axios.post(`https://api.fenixodex.net/api/api/users/edit`, {"field": "phone_number", "value": phone }, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {console.log("success")
            })
            .catch(function (error) {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )
            });
        axios.post(`https://api.fenixodex.net/api/api/users/edit`, {"field": "city", "value": city }, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {console.log("success")
            })
            .catch(function (error) {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )
            });
        axios.post(`https://api.fenixodex.net/api/api/users/edit`, {"field": "country", "value": country }, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {
                enqueueSnackbar(`Успешно!`, {action, 'variant': 'success', 'className': "snacksuccess"});
                setTimeout(() => document.location.reload(), 700)
            })
            .catch(function (error) {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )
            });
    };
    const [hdnpp, setHdnpp] = useState(true)

    const acceptShow = () => {
        const accept_passchange = document.getElementById("accept_passchange")
        const accept_passchange2 = document.getElementById("accept_passchange2")
        accept_passchange.classList.remove("hidden")
        accept_passchange2.classList.add("hidden")
    }

    const changePass = () => {
        axios.post(`https://api.fenixodex.net/api/api/users/password/change`, {password, "repeatPassword": passwordRepeat}, {headers: {"Authorization" : `Bearer ${token}`}})
            .then(res => {
                enqueueSnackbar(`Ваш пароль изменен`, {action, 'variant': 'success', 'className': "snacksuccess"});
                setHdnpp(true)
            })
            .catch(function (error) {
                error.response.data.detail.map(
                    errormsg => {
                        enqueueSnackbar(`Возникла ошибка: ${errormsg.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                    }
                )
            });
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: yellow[600],
            },
        },
    });

    const closePopUp = (event) => {
        event.stopPropagation()
        setHdnpp(true)
    }
    return (
        <div className="tabs__body ">


            <div className={`changepass-popup-window ${hdnpp ? "hidden" : ""}`} onClick={(event) => closePopUp(event)}>
                <div className="changepass-popup" onClick={(event) => event.stopPropagation()}>
                    <h3 className="changepass-title">Смена пароля</h3>
                    <div className="date__inp-wrap2">
                        <input type="password" onChange={e =>  handleChangePassword(e)} value={password} placeholder={"новый пароль"}  />
                    </div>
                    <div className="date__inp-wrap2">
                        <input type="password" onChange={e =>  handleChangePasswordRepeat(e)} value={passwordRepeat} placeholder={"повтор нового пароля"} />
                    </div>
                    <button className="changepass-button" onClick={changePass}>Сменить пароль</button>
                </div>
            </div>

            <div className="account__box vid">
                <div className="date__title">Личные данные</div>
                <div className="date__body">
                    <div className="date__line">
                        <div className="date__l-title">Персональная информация</div>
                        <div className="date__l-title-sub">
                            Заполняйте согласно паспортным данным
                        </div>
                        <div className="date__l-box">
                            <div className="date__inp-wrap">
                                <p>Имя</p>
                                <input type="text" onChange={e =>  handleChangeName(e)} value={name} placeholder={userData.name} defaultValue={userData.name} />
                            </div>
                            <div className="date__inp-wrap">
                                <p>Отображаемое имя</p>
                                <input type="text" onChange={e =>  handleChangeUsername(e)} value={username} placeholder={userData.displayed_name} defaultValue={userData.displayed_name} />
                            </div>
                        </div>
                        <div className="date__l-box">
                            <div className="date__inp-wrap">
                                <p>Фамилия</p>
                                <input type="text" onChange={e =>  handleChangeSurname(e)} value={surname} placeholder={userData.surname} defaultValue={userData.surname} />
                            </div>
                            <div className="date__inp-wrap">
                            </div>
                        </div>
                    </div>
                    <div className="date__line">
                        <div className="date__l-title">Контактная информация</div>
                        <div className="date__l-title-sub">
                            При заполнении email и телефона убедитесь, что данные
                            корректны
                        </div>
                        <div className="date__l-box">
                            <div className="date__inp-wrap">
                                <p>Email</p>
                                <input type="text" onChange={e => emailHandler(e) && handleChangeEmail} disabled value={email} onBlur={e => blurHandler(e)} name="email" type="email" placeholder={userData.email} defaultValue={userData.email} />
                            </div>
                            <div className="date__inp-wrap">
                                <p>Cтрана</p>
                                <input type="text" onChange={e =>  handleChangeCountry(e)} value={country} placeholder={userData.country} defaultValue={userData.country} />
                            </div>
                        </div>
                        <div className="date__l-box">
                            <div className="date__inp-wrap">
                                <p>Телефон</p>
                                <input type="text" onChange={e =>  handleChangePhone(e)} disabled value={phone} placeholder={userData.phone_number} defaultValue={userData.phone_number} />
                            </div>
                            <div className="date__inp-wrap">
                                <p>Город</p>
                                <input type="text" onChange={e =>  handleChangeCity(e)} value={city} placeholder={userData.city} defaultValue={userData.city} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="date__bottom">
                    <button className="date1" id="accept_passchange2" onClick={() => {setHdnpp(false)}}>
                        Смена пароля
                    </button>
                    <button disabled={!formValid} onClick={handleSubmit} className="date2">
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Me;