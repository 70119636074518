import React, {useEffect, useState} from 'react';
import '../auth.sass'
import axios from "axios";
import {useNavigate} from "react-router";
import {NavLink} from "react-router-dom";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const Register = (props) => {
    const [userData, setUserData] = useState([]);
    const action = snackbarId => (
        <button onClick={() => { closeSnackbar(snackbarId) }}>
            <CloseIcon/>
        </button>
    );
    localStorage.clear();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [emailError, setEmailError] = useState('e-mail cannot be empty!');
    const [passwordError, setPasswordError] = useState('password cannot be empty!');

    const [emailDirty, setEmailDirty] = useState(false);
    const [passwordDirty, setPasswordDirty] = useState(false);

    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if (emailError || passwordError ) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    },[emailError, passwordError])

    const blurHandler = (e) =>  {
        switch (e.target.name){
            case 'email':
                setEmailDirty(true)
                break
            case 'password':
                setPasswordDirty(true)
                break
        }

    };

    const emailHandler = (e) => {
        setEmail(e.target.value)
        const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!re.test(String(e.target.value).toLowerCase())) {
            setEmailError('incorrect e-mail')
            if(!e.target.value){
                setEmailError('e-mail cannot be empty!')
            }
        }
        else{
            setEmailError("")
        }
    }

    const passwordHandler = (e) => {
        setPassword(e.target.value)
        if (e.target.value.length < 4) {
            setPasswordError('the password is too short!')
            if(!e.target.value){
                setPasswordError('password cannot be empty!')
            }
        }else{
            setPasswordError("")
        }
    };
    const handleChangeEmail = event => {
        setEmail(event.target.value);
    };
    const handleChangePassword = event => {
        setPassword(event.target.value);
    };

    let navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
            axios.post(`https://api.fenixodex.net/api/api/token`, {email, password}, {headers:{"Content-Type": "multipart/form-data"}})
                .then(res => {
                    setEmail('');
                    setPassword('');
                    localStorage.setItem('token', res.data.access_token);
                    navigate(`../market?symbol=BTCUSDT`, { replace: true });
                    props.logIn()
                    const userToken = localStorage.getItem('token');

                const requireUserData = () => axios.get(
                    `https://api.fenixodex.net/api/api/users/me`,
                    {
                        headers: {"Authorization" : `Bearer ${userToken}`}
                    })
                    .then(res => {
                        console.log(res.data);
                        setUserData(res.data);
                        localStorage.setItem('userEmail', res.data.email);
                        localStorage.setItem('userName', res.data.name);
                        localStorage.setItem('userSurname', res.data.surname);
                    })
                    requireUserData();

            })
                .catch( error => {
                    enqueueSnackbar(`Возникла ошибка: ${error.response.data.detail.msg}`, {action, 'variant': 'error', 'className': "snackerror"});
                });
    };
    const [passwordShown, setPasswordShown] = useState(false);

    return (
        <div className="flex-center">
                <form className="loginform">
                    <h1 className="loginform__title">Войти</h1>
                    {(emailDirty && emailError) && <div className="error" style={{color: "red"}}>{emailError}</div>}
                    <input onChange={e => emailHandler(e) && handleChangeEmail} value={email} onBlur={e => blurHandler(e)} name="email" type="email" className="loginform__input" placeholder="Почта"/>
                    <div className="error" id="FailedAuth" style={{color: "red"}}>{passwordError}</div>
                    <div className="loginform__input-container">
                    <input type={passwordShown ? "" : "password"} onChange={e => passwordHandler(e) && handleChangePassword} value={password} onBlur={e => blurHandler(e)} name="password" className="loginform__input-password" placeholder="Пароль"/>
                        {passwordShown ? <VisibilityOffIcon onClick={() => setPasswordShown(!passwordShown)} className="visibilityIcon"/> : <VisibilityIcon onClick={() => setPasswordShown(!passwordShown)} className="visibilityIcon"/>}
                    </div>
                    <div className="loginform__forgot-container"> <a href="/recovery" className="loginform__forgot">забыли пароль?</a> </div>
                    <button className="loginform__button" disabled={!formValid} onClick={handleSubmit}>Войти</button>
                    <div className="logonform__iydha-container"><p className="loginform__iydha">Если у Вас ещё нет аккаунта, <NavLink className="loginform__link" to="/register">зарегистрируйтесь здесь!</NavLink></p></div>
                </form>

        </div>
    );
};

export default Register;
